exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accomplishments-index-js": () => import("./../../../src/pages/accomplishments/index.js" /* webpackChunkName: "component---src-pages-accomplishments-index-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-career-welfare-js": () => import("./../../../src/pages/career/welfare.js" /* webpackChunkName: "component---src-pages-career-welfare-js" */),
  "component---src-pages-company-certificates-js": () => import("./../../../src/pages/company/certificates.js" /* webpackChunkName: "component---src-pages-company-certificates-js" */),
  "component---src-pages-company-ci-js": () => import("./../../../src/pages/company/ci.js" /* webpackChunkName: "component---src-pages-company-ci-js" */),
  "component---src-pages-company-contact-js": () => import("./../../../src/pages/company/contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-company-esg-js": () => import("./../../../src/pages/company/esg.js" /* webpackChunkName: "component---src-pages-company-esg-js" */),
  "component---src-pages-company-history-js": () => import("./../../../src/pages/company/history.js" /* webpackChunkName: "component---src-pages-company-history-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-company-inquiry-js": () => import("./../../../src/pages/company/inquiry.js" /* webpackChunkName: "component---src-pages-company-inquiry-js" */),
  "component---src-pages-company-organization-js": () => import("./../../../src/pages/company/organization.js" /* webpackChunkName: "component---src-pages-company-organization-js" */),
  "component---src-pages-esg-csr-js": () => import("./../../../src/pages/esg/csr.js" /* webpackChunkName: "component---src-pages-esg-csr-js" */),
  "component---src-pages-esg-esg-data-js": () => import("./../../../src/pages/esg/esgData.js" /* webpackChunkName: "component---src-pages-esg-esg-data-js" */),
  "component---src-pages-esg-index-js": () => import("./../../../src/pages/esg/index.js" /* webpackChunkName: "component---src-pages-esg-index-js" */),
  "component---src-pages-esg-report-center-js": () => import("./../../../src/pages/esg/report-center.js" /* webpackChunkName: "component---src-pages-esg-report-center-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-car-battery-js": () => import("./../../../src/pages/industries/car-battery.js" /* webpackChunkName: "component---src-pages-industries-car-battery-js" */),
  "component---src-pages-industries-material-js": () => import("./../../../src/pages/industries/material.js" /* webpackChunkName: "component---src-pages-industries-material-js" */),
  "component---src-pages-industries-petro-chem-js": () => import("./../../../src/pages/industries/petro-chem.js" /* webpackChunkName: "component---src-pages-industries-petro-chem-js" */),
  "component---src-pages-industries-semiconductor-js": () => import("./../../../src/pages/industries/semiconductor.js" /* webpackChunkName: "component---src-pages-industries-semiconductor-js" */),
  "component---src-pages-industries-shipbuilding-js": () => import("./../../../src/pages/industries/shipbuilding.js" /* webpackChunkName: "component---src-pages-industries-shipbuilding-js" */),
  "component---src-pages-industry-business-js": () => import("./../../../src/pages/industry/business.js" /* webpackChunkName: "component---src-pages-industry-business-js" */),
  "component---src-pages-industry-car-battery-js": () => import("./../../../src/pages/industry/car-battery.js" /* webpackChunkName: "component---src-pages-industry-car-battery-js" */),
  "component---src-pages-industry-client-js": () => import("./../../../src/pages/industry/client.js" /* webpackChunkName: "component---src-pages-industry-client-js" */),
  "component---src-pages-industry-dxwith-js": () => import("./../../../src/pages/industry/dxwith.js" /* webpackChunkName: "component---src-pages-industry-dxwith-js" */),
  "component---src-pages-industry-material-js": () => import("./../../../src/pages/industry/material.js" /* webpackChunkName: "component---src-pages-industry-material-js" */),
  "component---src-pages-industry-petro-chem-js": () => import("./../../../src/pages/industry/petro-chem.js" /* webpackChunkName: "component---src-pages-industry-petro-chem-js" */),
  "component---src-pages-industry-result-js": () => import("./../../../src/pages/industry/result.js" /* webpackChunkName: "component---src-pages-industry-result-js" */),
  "component---src-pages-industry-semiconductor-js": () => import("./../../../src/pages/industry/semiconductor.js" /* webpackChunkName: "component---src-pages-industry-semiconductor-js" */),
  "component---src-pages-industry-shipbuilding-js": () => import("./../../../src/pages/industry/shipbuilding.js" /* webpackChunkName: "component---src-pages-industry-shipbuilding-js" */),
  "component---src-pages-industry-solution-calendar-js": () => import("./../../../src/pages/industry/solution-calendar.js" /* webpackChunkName: "component---src-pages-industry-solution-calendar-js" */),
  "component---src-pages-industry-solution-eskk-js": () => import("./../../../src/pages/industry/solution-eskk.js" /* webpackChunkName: "component---src-pages-industry-solution-eskk-js" */),
  "component---src-pages-industry-solution-flow-js": () => import("./../../../src/pages/industry/solution-flow.js" /* webpackChunkName: "component---src-pages-industry-solution-flow-js" */),
  "component---src-pages-industry-solution-frame-js": () => import("./../../../src/pages/industry/solution-frame.js" /* webpackChunkName: "component---src-pages-industry-solution-frame-js" */),
  "component---src-pages-industry-solution-js": () => import("./../../../src/pages/industry/solution.js" /* webpackChunkName: "component---src-pages-industry-solution-js" */),
  "component---src-pages-industry-solution-kiosk-js": () => import("./../../../src/pages/industry/solution-kiosk.js" /* webpackChunkName: "component---src-pages-industry-solution-kiosk-js" */),
  "component---src-pages-industry-solution-lims-js": () => import("./../../../src/pages/industry/solution-lims.js" /* webpackChunkName: "component---src-pages-industry-solution-lims-js" */),
  "component---src-pages-industry-solution-ocr-js": () => import("./../../../src/pages/industry/solution-ocr.js" /* webpackChunkName: "component---src-pages-industry-solution-ocr-js" */),
  "component---src-pages-industry-solution-poq-js": () => import("./../../../src/pages/industry/solution-poq.js" /* webpackChunkName: "component---src-pages-industry-solution-poq-js" */),
  "component---src-pages-industry-solution-pvot-js": () => import("./../../../src/pages/industry/solution-pvot.js" /* webpackChunkName: "component---src-pages-industry-solution-pvot-js" */),
  "component---src-pages-solutions-automation-js": () => import("./../../../src/pages/solutions/automation.js" /* webpackChunkName: "component---src-pages-solutions-automation-js" */),
  "component---src-pages-solutions-equipment-js": () => import("./../../../src/pages/solutions/equipment.js" /* webpackChunkName: "component---src-pages-solutions-equipment-js" */),
  "component---src-pages-solutions-logistics-js": () => import("./../../../src/pages/solutions/logistics.js" /* webpackChunkName: "component---src-pages-solutions-logistics-js" */),
  "component---src-pages-solutions-methodology-js": () => import("./../../../src/pages/solutions/methodology.js" /* webpackChunkName: "component---src-pages-solutions-methodology-js" */),
  "component---src-pages-solutions-operation-js": () => import("./../../../src/pages/solutions/operation.js" /* webpackChunkName: "component---src-pages-solutions-operation-js" */),
  "component---src-pages-solutions-quality-js": () => import("./../../../src/pages/solutions/quality.js" /* webpackChunkName: "component---src-pages-solutions-quality-js" */),
  "component---src-pages-support-contact-js": () => import("./../../../src/pages/support/contact.js" /* webpackChunkName: "component---src-pages-support-contact-js" */),
  "component---src-pages-support-download-js": () => import("./../../../src/pages/support/download.js" /* webpackChunkName: "component---src-pages-support-download-js" */),
  "component---src-pages-support-inquiry-js": () => import("./../../../src/pages/support/inquiry.js" /* webpackChunkName: "component---src-pages-support-inquiry-js" */),
  "component---src-pages-support-news-js": () => import("./../../../src/pages/support/news.js" /* webpackChunkName: "component---src-pages-support-news-js" */),
  "component---src-pages-support-pr-center-js": () => import("./../../../src/pages/support/prCenter.js" /* webpackChunkName: "component---src-pages-support-pr-center-js" */),
  "component---src-pages-support-pr-js": () => import("./../../../src/pages/support/pr.js" /* webpackChunkName: "component---src-pages-support-pr-js" */),
  "component---src-pages-support-privacy-js": () => import("./../../../src/pages/support/privacy.js" /* webpackChunkName: "component---src-pages-support-privacy-js" */)
}

